import {
  priceLoadStateType,
  priceLoadSecondDropdownType,
  generalLoadType,
  ActivitiesListType,
  FittingsListType,
  RentListType,
  SightseeingListType,
  TransportListType,
  ServiceListType,
  Gps,
  PromotionListType,
} from "../Types/types";

export const gpsLoad: Gps = {
  grad1: "",
  minute1: "",
  sekunden1: "",
  grad2: "",
  minute2: "",
  sekunden2: "",
  GPSA: "",
  GPSB: "",
};

export const contactLoad = {
  name: "",
  street: "",
  phone: "",
  postCode: "",
  email: "",
  city: "",
  homepage: "",
  state: "",
};

export const registerLoad = {
  name: "",
  surname: "",
  email: "",
  password: "",
};

export const priceLoad = {
  zweipersonen: "Kostenlos",
  sidezweipersonen: "Kostenlos",
  proPerson: "inklusive",
  proKid: "inklusive",
  proPet: "Nein",
  taxPerson: "Nein",
  taxKid: "Nein",
  taxPet: "Nein",
  electricity: "Nein",
  water: "Nein",
  shower: "Nein",
  wc: "Nein",
  casset: "Nein",
  ground: "Nein",
  wlan: "Nein",
  trashbin: "Nein",
  wash: "Nein",
  // barrier: "Nein",
  // playground: "Nein",
} as priceLoadStateType;

export const priceLoadStatus = {
  zweipersonen: true,
  sidezweipersonen: true,
  proPerson: true,
  proKid: true,
  proPet: true,
  taxPerson: true,
  taxKid: true,
  taxPet: true,
  electricity: true,
  water: true,
  shower: true,
  wc: true,
  casset: true,
  ground: true,
  wlan: true,
  trashbin: true,
  wash: true,
  barrier: true,
  playground: true,
};

export const priceLoadStatusEmpty = {
  zweipersonen: false,
  sidezweipersonen: false,
  proPerson: false,
  proKid: false,
  proPet: false,
  taxPerson: false,
  taxKid: false,
  taxPet: false,
  electricity: false,
  water: false,
  shower: false,
  wc: false,
  casset: false,
  ground: false,
  wlan: false,
  trashbin: false,
  wash: false,
  barrier: false,
  playground: false,
};

export const priceSeasonLoadStatus = {
  season: true,
};

export const priceArrivalLoadStatus = {
  arrival: true,
};

export const stayLoadStatus = {
  stay: true,
};

export const priceLoadSecondDropdown: priceLoadSecondDropdownType = {
  proPet: false,
  taxPerson: false,
  taxKid: false,
  taxPet: false,
  electricity: false,
  water: false,
  shower: false,
  wc: false,
  casset: false,
  ground: false,
  wlan: false,
  trashbin: false,
  wash: false,
};

export const generalLoad = {
  season: "Ganzjährig",
  parkplaceCount: "",
  maximumLength: "",
  maximumStay: "",
} as generalLoadType;

export const ActivitiesLoad: ActivitiesListType = {
  cycle: false,
  mountainBiking: false,
  poolswim: false,
  swim: false,
  hiking: false,
  fishing: false,
  paddling: false,
  rafting: false,
  sailing: false,
  diving: false,
  golf: false,
  tennis: false,
  snowski: false,
  volleyball: false,
  horsebackRiding: false,
  beach: false,
  badminton: false,
  boccia: false,
  tableTennis: false,
  skiing: false,
  snowhike: false,
  climbing: false,
  railway: false,
  cogRailway: false,
  ship: false,
  paragliding: false,
  balloonRide: false,
  safariTour: false,
  naturist: false,
  thermalBath: false,
  canoe: false,
  miniGolf: false,
  waterPark: false,
  lakeSwimming: false,
};

export const PromotionListLoad: PromotionListType = {
  acsi: false,
  adac: false,
  campingkey: false,
  ccicss: false,
  dcc: false,
  occ: false,
  tcs: false,
  cheque: false,
};

export const FittingsListLoad: FittingsListType = {
  electricity: false,
  water: false,
  toilet: false,
  shower: false,
  rentaBathrooms: false,
  wifi: false,
  handicappedAccessible: false,
  pets: false,
  dogShower: false,
  rubbish: false,
  chemistryWC: false,
  disposal: false,
  automat: false,
  waterOnSite: false,
  sewageOnSite: false,
  TVconnection: false,
  playground: false,
  childrenPlayroom: false,
  childrenAnimation: false,
  babyChangingRoom: false,
  recreationRoom: false,
  dishwasher: false,
  washingMachine: false,
  laundry: false,
  dryerRoom: false,
  wellness: false,
  sauna: false,
  massage: false,
  restaurant: false,
  kiosk: false,
  telmarkt: false,
  bakery: false,
  coffee: false,
  barbecue: false,
  winterCamping: false,
  multilingual: false,
  wine: false,
  beerGarden: false,
  overSize: false,
  farm: false,
  caravanAllowed: false,
};

export const RentListLoad: RentListType = {
  mobilehome: false,
  flats: false,
  campingBarrel: false,
  cabin: false,
  caravan: false,
  tent: false,
  treehouse: false,
  trailer: false,
  rentalBathroom: false,
};

export const SightseeingListLoad: SightseeingListType = {
  view: false,
  castle: false,
  history: false,
  museum: false,
  church: false,
  mosque: false,
  synagogue: false,
  zone: false,
  shop: false,
  movietheater: false,
  zoo: false,
  stadion: false,
};

export const TransportListLoad: TransportListType = {
  bus: false,
  tram: false,
  taxi: false,
  train: false,
  ship: false,
};

export const ServiceListLoad: ServiceListType = {
  breadService: false,
  touristInfo: false,
  gas: false,
  hairdresser: false,
  pharmacy: false,
  postOffice: false,
  cashDispenser: false,
  LaundryService: false,
  BicycleRental: false,
  bicycleRepair: false,
  carRental: false,
  workshop: false,
  boatHire: false,
  slipway: false,
  petrolStation: false,
  carFree: false,
};
