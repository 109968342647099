import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function TopHint() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Danke, dass Sie uns helfen, die Informationen zu Ihrem
          Stellplatz/Campingplatz für Ihre Gäste zu aktualisieren.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Bitte füllen Sie die nachstehenden Felder vollständig aus.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Nach dem Qualitäts-Check und der Aktualisierung Ihrer Daten erhalten
          Sie von uns einen Link zur Freigabe. Damit ist sichergestellt, dass
          alles korrekt ist – ganz im Sinne zufriedener Gäste.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Falls Sie Fragen haben, melden Sie sich gerne bei uns.
        </Typography>
      </Grid>
      <Grid container item>
        <br />
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Ihr Team von CAMP/24 Check-In
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Telefon: 07731 90 72 610
        </Typography>
      </Grid>
    </Grid>
  );
}
