import create, { State } from "zustand";
import {
  contactLoad,
  priceLoad,
  generalLoad,
  ActivitiesLoad,
  FittingsListLoad,
  RentListLoad,
  ServiceListLoad,
  SightseeingListLoad,
  TransportListLoad,
  gpsLoad,
  priceLoadStatus,
  priceSeasonLoadStatus,
  priceLoadStatusEmpty,
  registerLoad,
  stayLoadStatus,
  priceArrivalLoadStatus,
  PromotionListLoad,
} from "./models";
import {
  price,
  contact,
  general,
  priceLoadStateType,
  ActivitiesListType,
  FittingsListType,
  RentListType,
  ServiceListType,
  SightseeingListType,
  TransportListType,
  FileDescriptionType,
  Gps,
  priceLoadStatusType,
  priceSeasonLoadStatusType,
  register,
  stayStatusType,
  priceArrivalLoadStatusType,
  PromotionListType,
} from "../Types/types";
import { submitCreateForm, submitForm, submitRegister } from "../Utils/helpers";

export interface FormState {
  admin: boolean;
  registeredx: boolean;
  limit: boolean;
  url: string;
  successRegister: boolean;
  errorRegister: boolean;
  token: string;
  success: boolean;
  error: boolean;
  isBookable: boolean;
  isActive: boolean;
  edit: boolean;
  gps: Gps;
  parkingArealId: string;
  submitted: boolean;
  backdrop: boolean;
  files: any[];
  fileDescription: FileDescriptionType[];
  contact: contact;
  price: price;
  customer: boolean;
  general: general;
  activities: ActivitiesListType;
  promotion: PromotionListType;
  fittings: FittingsListType;
  rent: RentListType;
  service: ServiceListType;
  sightseeing: SightseeingListType;
  transport: TransportListType;
  priceLoadStatus: priceLoadStatusType;
  registered: boolean;
  register: register;
  submittedRegister: boolean;
  contactOld: contact;
  priceOld: price;
  generalOld: general;
  activitiesOld: ActivitiesListType;
  fittingsOld: FittingsListType;
  rentOld: RentListType;
  serviceOld: ServiceListType;
  sightseeingOld: SightseeingListType;
  transportOld: TransportListType;
  submitForm: () => Promise<boolean>;
  submitRegister: () => Promise<boolean>;
  reset: () => void;
  setFiles: (file: any) => void;
  setLimit: (flag: boolean) => void;
  setCustomer: (flag: boolean) => void;
  setRegisteredX: (flag: boolean) => void;
  addFiles: (file: any) => void;
  removeFile: (id: string) => void;
  setFileDescription: (
    description: string,
    id: string,
    order: string,
    base: any
  ) => void;
  setUrl: (url: string) => void;
  updateFileDescription: (description: string, id: string) => void;
  updateFileOrder: (order: string, id: string) => void;
  removeFileDescription: (id: string) => void;
  updateRent: (key: string, value: boolean) => void;
  updateService: (key: string, value: boolean) => void;
  updateSightseeing: (key: string, value: boolean) => void;
  updateTransport: (key: string, value: boolean) => void;
  updateFittings: (key: string, value: boolean) => void;
  updateActivities: (key: string, value: boolean) => void;
  updatePromotion: (key: string, value: boolean) => void;
  updateContact: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  updateRegister: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  updatePrice: (key: string, value: string) => void;
  updateGps: (key: string, value: string) => void;
  updateGeneral: (key: string, value: string) => void;
  setBackDrop: (val: boolean) => void;
  setEdit: (val: any) => void;
  setBookable: (val: boolean) => void;
  setActive: (val: boolean) => void;
  setAll: (val: any) => void;
  setAllAdmin: (val: any, old: any) => void;
  setSuccess: (flag: boolean) => void;
  setSuccessRegister: (flag: boolean) => void;
  setErrorRegister: (flag: boolean) => void;
  setError: (flag: boolean) => void;
  setToken: (token: string) => void;
  setPriceSeasonStatus: (val: boolean) => void;
  setPriceArrivalStatus: (val: boolean) => void;
  setStayStatus: (val: boolean) => void;
  setWithRegister: (val: boolean) => void;
  priceSeasonStatus: priceSeasonLoadStatusType;
  priceArrivalStatus: priceArrivalLoadStatusType;
  stayStatus: stayStatusType;
  registerEmail: boolean;
  withRegister: boolean;
}

export const formStore = create<FormState>((set, get) => ({
  customer: false,
  withRegister: false,
  registerEmail: false,
  admin: false,
  registeredx: false,
  limit: false,
  url: "",
  registered: false,
  token: "",
  success: false,
  error: false,
  successRegister: false,
  errorRegister: false,
  parkingArealId: "",
  backdrop: true,
  edit: false,
  submittedRegister: false,
  submitted: false,
  isBookable: false,
  isActive: true,
  gps: gpsLoad,
  files: [],
  fileDescription: [],
  contact: contactLoad as contact,
  register: registerLoad as register,
  price: priceLoad as price,
  general: generalLoad as general,
  activities: ActivitiesLoad as ActivitiesListType,
  promotion: PromotionListLoad as PromotionListType,
  fittings: FittingsListLoad as FittingsListType,
  rent: RentListLoad as RentListType,
  service: ServiceListLoad as ServiceListType,
  sightseeing: SightseeingListLoad as SightseeingListType,
  transport: TransportListLoad as TransportListType,
  priceLoadStatus: priceLoadStatus,
  priceSeasonStatus: priceSeasonLoadStatus,
  priceArrivalStatus: priceArrivalLoadStatus,
  stayStatus: stayLoadStatus,
  priceOld: priceLoad as price,
  generalOld: generalLoad as general,
  activitiesOld: ActivitiesLoad as ActivitiesListType,
  fittingsOld: FittingsListLoad as FittingsListType,
  rentOld: RentListLoad as RentListType,
  serviceOld: ServiceListLoad as ServiceListType,
  sightseeingOld: SightseeingListLoad as SightseeingListType,
  transportOld: TransportListLoad as TransportListType,
  contactOld: contactLoad as contact,
  setWithRegister: (flag: boolean) => {
    set((state) => ({
      withRegister: flag,
    }));
  },
  setSuccessRegister: (flag: boolean) => {
    set((state) => ({
      successRegister: flag,
    }));
  },
  setErrorRegister: (flag: boolean) => {
    set((state) => ({
      errorRegister: flag,
    }));
  },
  setRegisteredX: (flag: boolean) => {
    set((state) => ({
      registeredx: flag,
    }));
  },
  setCustomer: (flag: boolean) => {
    set((state) => ({
      customer: flag,
    }));
  },
  setLimit: (flag: boolean) => {
    set((state) => ({
      limit: flag,
    }));
  },
  setSuccess: (flag: boolean) => {
    set((state) => ({
      success: flag,
    }));
  },
  setToken: (token: string) => {
    set((state) => ({
      token: token,
    }));
  },
  setError: (flag: boolean) => {
    set((state) => ({
      error: flag,
    }));
  },
  setUrl: (url: string) => {
    set((state) => ({
      url: url,
    }));
  },
  setEdit: (val: boolean) => {
    set((state) => ({
      edit: val,
    }));
  },
  setBookable: (val: boolean) => {
    set((state) => ({
      isBookable: val,
    }));
  },
  setActive: (val: boolean) => {
    set((state) => ({
      isActive: val,
    }));
  },
  setAll: (val: any) => {
    get().reset();
    let priceLoadStatusX: priceLoadStatusType = priceLoadStatus;
    Object.entries(get().priceLoadStatus).forEach(
      ([id, value]) => (priceLoadStatusX[id] = false)
    );
    set((state) => ({
      backdrop: false,
      price: val.price,
      contact: val.contact,
      general: val.general,
      activities: val.activities,
      fittings: val.fittings,
      sightseeing: val.sightseeing,
      transport: val.transport,
      gps: val.gps,
      priceLoadStatus: priceLoadStatus,
      priceSeasonStatus: { season: false },
      priceArrivalStatus: { arrival: false },
      stayStatus: { stay: false },
      isBookable: val.isBookable,
      isActive: val.isActive,
      parkingArealId: val.parkingArealId,
      edit: val.edit,
      registered: val.register,
      promotion: val.promotion,
      rent: val.rent,
      service: val.service,
    }));
  },
  setAllAdmin: (val: any, old: any) => {
    get().reset();
    let priceLoadStatusX: priceLoadStatusType = priceLoadStatus;
    Object.entries(get().priceLoadStatus).forEach(
      ([id, value]) => (priceLoadStatusX[id] = false)
    );
    set((state) => ({
      backdrop: false,
      priceOld: old.price,
      contactOld: old.contact,
      generalOld: old.general,
      activitiesOld: old.activities,
      fittingsOld: old.fittings,
      sightseeingOld: old.sightseeing,
      transportOld: old.transport,
      rentOld: old.rent,
      serviceOld: old.service,
      price: val.price,
      contact: val.contact,
      general: val.general,
      activities: val.activities,
      fittings: val.fittings,
      sightseeing: val.sightseeing,
      transport: val.transport,
      gps: val.gps,
      priceLoadStatus: priceLoadStatus,
      priceSeasonStatus: { season: false },
      priceArrivalStatus: { arrival: false },
      stayStatus: { stay: false },
      isBookable: val.isBookable,
      isActive: val.isActive,
      parkingArealId: val.parkingArealId,
      edit: val.edit,
      registered: val.register,
      fileDescription: val.base,
      promotion: val.promotion,
      admin: true,
      rent: val.rent,
      service: val.service,
    }));
  },
  reset: () => {
    set((state) => ({
      contact: contactLoad as contact,
      price: priceLoad as price,
      general: generalLoad as general,
      activities: ActivitiesLoad as ActivitiesListType,
      fittings: FittingsListLoad as FittingsListType,
      rent: RentListLoad as RentListType,
      service: ServiceListLoad as ServiceListType,
      sightseeing: SightseeingListLoad as SightseeingListType,
      transport: TransportListLoad as TransportListType,
      promotion: PromotionListLoad as PromotionListType,
      priceLoadStatus: priceLoadStatusEmpty,
      priceSeasonStatus: priceSeasonLoadStatus,
      priceArrivalStatus: priceArrivalLoadStatus,
      stayStatus: stayLoadStatus,
      success: false,
      error: false,
      parkingArealId: "",
      backdrop: false,
      edit: false,
      submitted: false,
      isBookable: false,
      isActive: true,
      gps: gpsLoad,
    }));
  },
  setPriceSeasonStatus: (val: boolean) => {
    set((state) => ({
      priceSeasonStatus: { season: val },
    }));
  },
  setPriceArrivalStatus: (val: boolean) => {
    set((state) => ({
      priceArrivalStatus: { arrival: val },
    }));
  },
  setStayStatus: (val: boolean) => {
    set((state) => ({
      stayStatus: { stay: val },
    }));
  },
  setBackDrop: (val: boolean) => {
    set((state) => ({
      backdrop: val,
    }));
  },
  submitRegister: async () => {
    const reg = Object.entries(get().register).filter(
      ([key, value]) => value === ""
    );
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email = get().register.email;
    if (reg.length !== 0) {
      set((state) => ({
        submittedRegister: true,
      }));
      return false;
    } else if (re.test(email) === false) {
      set((state) => ({
        registerEmail: true,
      }));
      return false;
    } else {
      const rr = await submitRegister(get().register, get().token);

      if (rr === true) {
        setTimeout(() => {
          set((state) => ({
            successRegister: false,
          }));
        }, 6000);
        set((state) => ({
          successRegister: true,
        }));

        return true;
      } else {
        set((state) => ({
          submittedRegister: true,
        }));

        set((state) => ({
          errorRegister: true,
        }));
        setTimeout(() => {
          set((state) => ({
            errorRegister: false,
          }));
        }, 6000);
        return false;
      }
    }
  },
  submitForm: async () => {
    // console.log(get().files);

    const contact = Object.entries(get().contact).filter(
      ([key, value]) => value === ""
    );
    const price = Object.entries(get().price).filter(
      ([key, value]) => value === ""
    );
    const general = Object.entries(get().general).filter(
      ([key, value]) => value === ""
    );
    if (get().customer === false) {
      if (price.length !== 0 || contact.length !== 0 || general.length !== 0) {
        set((state) => ({
          submitted: true,
        }));
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          return false;
        }, 300);
      });
    } else {
      return submitForm(
        get().contact,
        get().price,
        get().general,
        get().activities,
        get().fittings,
        get().sightseeing,
        get().transport,
        get().token,
        get().fileDescription,
        get().url,
        get().promotion,
        get().rent,
        get().service
      )
        .then((res: any) => {
          if (get().withRegister !== true) {
            setTimeout(() => {
              set((state) => ({
                success: false,
              }));
            }, 6000);
            set((state) => ({
              success: true,
            }));
          }

          set((state) => ({
            submitted: false,
          }));
          return true;
        })
        .catch((err: any) => {
          // set((state) => ({
          //   submitted: true,
          // }));
          if (get().withRegister !== true) {
            set((state) => ({
              error: true,
            }));
            setTimeout(() => {
              set((state) => ({
                error: false,
              }));
            }, 6000);
          }
          return false;
        });
    }
  },
  updateGps: (key, value) => {
    set((state) => ({
      gps: { ...state.gps, [key]: value },
    }));
  },
  setFileDescription: (
    description: string,
    id: string,
    order: string,
    base: any
  ) => {
    set((state) => ({
      fileDescription: [
        ...state.fileDescription,
        {
          id: id,
          order: order,
          description,
          base,
        } as FileDescriptionType,
      ],
    }));
  },
  updateFileDescription: (description: string, id: string) => {
    set((state) => ({
      fileDescription: state.fileDescription.map((file) =>
        file.id === id
          ? ({ ...file, description: description } as FileDescriptionType)
          : file
      ),
    }));
  },
  updateFileOrder: (order: string, id: string) => {
    set((state) => ({
      fileDescription: state.fileDescription.map((file) =>
        file.id === id
          ? ({ ...file, order: order } as FileDescriptionType)
          : file
      ),
    }));
  },
  removeFileDescription: (id: string) => {
    let i = 0;
    set((state) => ({
      fileDescription: state.fileDescription.filter((fileDescription) => {
        if (fileDescription.id !== id) {
          i = i + 1;
          return (fileDescription.order = i.toString());
        }
      }),
    }));
  },
  addFiles: (file: any) => {
    set((state) => ({
      files: [...state.files, ...file],
    }));
  },
  setFiles: (file: any) => {
    set(() => ({
      files: file,
    }));
  },
  removeFile: (id: string) => {
    set((state) => ({
      files: state.files.filter((file) => file.name !== id),
    }));
  },
  updateRent: (key: string, value: boolean) => {
    set((state) => ({
      rent: {
        ...state.rent,
        ...{ [key]: value },
      } as RentListType,
    }));
  },
  updateService: (key: string, value: boolean) => {
    set((state) => ({
      service: {
        ...state.service,
        ...{ [key]: value },
      } as ServiceListType,
    }));
  },
  updateSightseeing: (key: string, value: boolean) => {
    set((state) => ({
      sightseeing: {
        ...state.sightseeing,
        ...{ [key]: value },
      } as SightseeingListType,
    }));
  },
  updateTransport: (key: string, value: boolean) => {
    set((state) => ({
      transport: {
        ...state.transport,
        ...{ [key]: value },
      } as TransportListType,
    }));
  },
  updateFittings: (key: string, value: boolean) => {
    set((state) => ({
      fittings: {
        ...state.fittings,
        ...{ [key]: value },
      } as FittingsListType,
    }));
  },
  updateActivities: (key: string, value: boolean) => {
    set((state) => ({
      activities: {
        ...state.activities,
        ...{ [key]: value },
      } as ActivitiesListType,
    }));
  },
  updatePromotion: (key: string, value: boolean) => {
    set((state) => ({
      promotion: {
        ...state.promotion,
        ...{ [key]: value },
      } as PromotionListType,
    }));
  },
  updateGeneral: (key: string, value: string) => {
    set((state) => ({
      general: {
        ...state.general,
        ...{ [key]: value },
      } as general,
    }));
  },
  updatePrice: (key: string, value: string) => {
    set((state) => ({
      price: {
        ...state.price,
        ...{ [key]: value },
      } as price,
    }));
  },
  updateContact: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    console.log(event.target);
    set((state) => ({
      contact: {
        ...state.contact,
        ...{ [event.target.id]: event.target.value },
      } as contact,
    }));
  },
  updateRegister: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    set((state) => ({
      register: {
        ...state.register,
        ...{ [event.target.id]: event.target.value },
      } as register,
    }));
  },
}));
