import * as React from "react";
import Contact from "./contact";
import Price from "./price";
import General from "./general";
import Fittings from "./Facilities/fittings";
import Activities from "./Facilities/activities";
import Sightseeing from "./Facilities/sightseeing";
import Transport from "./Facilities/transport";
import Service from "./Facilities/service";
import Rent from "./Facilities/rent";
import { DropPicture } from "./dropzone";
import TopHint from "./Hints/tophint";
import MiddleHint from "./Hints/middlehint";
import BottomHint from "./Hints/bottomhint";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  CardMedia,
  CardContent,
  Card,
  Grid,
  Box,
  Divider,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Select,
  MenuItem,
  CardActions,
} from "@mui/material";
import { formStore, FormState } from "../State/state";
import { ImageCard } from "./card";
import BackDropLoading from "./backdrop";
import { ArrowForward, SelectAllTwoTone } from "@mui/icons-material";
import Register from "./register";
import { URL } from "../Utils/helpers";
import { useNavigate } from "react-router-dom";
import NotRegisteringHint from "./Hints/notRegistering";
import WithRegisteringHint from "./Hints/withRegistering";
import { Promotion } from "./Facilities/promotion";

const theme = createTheme({
  palette: {
    primary: {
      main: "#54AE36",
    },
    secondary: {
      main: "#525252",
    },
    background: {
      default: "#fafafa",
    },
  },
});

export default function AutoGrid() {
  const state = formStore((state) => state.fileDescription);
  const edit = formStore((state) => state.edit);
  const limit = formStore((state) => state.limit);
  const admin = formStore((state) => state.admin);
  const customer = formStore((state) => state.customer);
  const registered = formStore((state) => state.registered);
  const backdrop = formStore((state) => state.backdrop);

  const submittedRegister = formStore((state) => state.submittedRegister);
  const submitted = formStore((state) => state.submitted);
  const success = formStore((state) => state.success);
  const error = formStore((state) => state.error);
  const registeredx = formStore((state) => state.registeredx);
  const fileDescription: FormState["fileDescription"] = formStore(
    (state) => state.fileDescription
  );
  const submitForm: FormState["submitForm"] = formStore(
    (state) => state.submitForm
  );
  const clickable = () => {
    submitForm();
  };

  const setAll = formStore((state) => state.setAll);

  const setAllAdmin = formStore((state) => state.setAllAdmin);

  const setCustomer = formStore((state) => state.setCustomer);

  const setUrl = formStore((state) => state.setUrl);

  const url = formStore((state) => state.url);

  const setToken = formStore((state) => state.setToken);

  const setLimit = formStore((state) => state.setLimit);

  const setRegisteredX = formStore((state) => state.setRegisteredX);

  const setBackdrop = formStore((state) => state.setBackDrop);

  let navigate = useNavigate();
  React.useEffect(() => {
    const token = window.location.href.split("token=")[1];
    const type: string = window.location.href.split("/")[3];

    interface urls {
      [key: string]: string;
    }

    const urls: urls = {
      orm: "orm",
      adm: "admin",
      form: "form",
      orx: "orx",
    };

    setUrl(urls[type]);

    if (token) {
      setToken(token);
      fetch(URL + urls[type] + "/" + token, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.status === 200) {
          return response.json();
          // } else {
          // console.error("error");
          // }
        })
        .then((data) => {
          if (data.message === "Limit exceeded") {
            setLimit(true);
            setBackdrop(false);
          } else if (data.message === "Already registered") {
            setRegisteredX(true);
            setBackdrop(false);
          } else if (type === "adm") {
            setAllAdmin(data.data.new, data.data.old);
          } else if (type === "orx") {
            setCustomer(true);
            setAll(data.data);
            setBackdrop(false);
          } else {
            setAll(data.data);
          }
        })
        .catch((error) => {
          // fakeAuthProvider.isAuthenticated = false;
          // if (error.status === 409) {
          // console.log(error);
          // setLimit(true);
          // }
          // setTimeout(() => {
          navigate("/", { replace: true });
          // }, 1000);
          console.error("Error:", error);
        });
    }

    // if (props.location.state) {
    //   setBackDrop(true);
    //   getParkingArealByIdAdmin(props.location.state.data)
    //     .then((result) => {
    //       setAll(result.data);
    //     })
    //     .catch((error) => console.error(error));
    // } else {
    //   reset();
    // }
  }, []);

  if (backdrop === true) {
    return <BackDropLoading />;
  } else {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Snackbar
            open={submitted}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">
              Füllen Sie die erforderlichen Felder aus.
            </Alert>
          </Snackbar>

          <Snackbar
            open={error}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">
              Es ist ein Fehler aufgetreten. Das Dokument wurde nicht
              gespeichert.
            </Alert>
          </Snackbar>

          <Snackbar
            open={success}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">Dokument gespeichert.</Alert>
          </Snackbar>
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              paddingTop: 50,
              backgroundColor: "#fafafa",
              paddingBottom: "50px",
            }}
          >
            {limit === true ? (
              <Grid
                style={{ paddingTop: "50px" }}
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography
                        align="center"
                        variant="h3"
                        style={{
                          marginTop: "20px",
                          marginLeft: "50px",
                        }}
                      >
                        Ihr Sicherheitslimit wurde überschritten.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : registeredx === true ? (
              <Grid
                style={{ paddingTop: "50px" }}
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography
                        align="center"
                        variant="h3"
                        style={{
                          marginTop: "20px",
                          marginLeft: "50px",
                        }}
                      >
                        Bereits registriert.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid
                        style={{ paddingTop: "10px" }}
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} md={12} justifyContent="center">
                          <Button
                            variant="outlined"
                            disableElevation
                            // style={{ color: "#ffffff" }}
                            color="primary"
                            onClick={() => {
                              window.location.href =
                                // @todo prodcution disabled
                                "https://meinplatz.camp24.xyz";
                              // "https://meinplatz.camp24checkin.de";
                            }}
                          >
                            Klicken Sie hier für die Anmeldung <ArrowForward />
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item xs={1} xl={1}>
                    {/* <Item>xs</Item> */}
                  </Grid>
                  <Grid item xs={10} xl={10}>
                    <Card>
                      <CardMedia
                        component="img"
                        // height="360"
                        image={require("../../Assets/Images/Frontend/title.png")}
                        alt="Camp24"
                      />
                      <CardContent>
                        {admin === false ? (
                          <Grid
                            item
                            style={{
                              paddingBottom: "20px",
                              paddingTop: "10px",
                            }}
                          >
                            <TopHint />
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item>
                          <Contact />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Price />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <General />
                        </Grid>
                        {admin === false ? (
                          <Grid item style={{ paddingTop: "25px" }}>
                            <MiddleHint />
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Fittings />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Activities />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Sightseeing />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Transport />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Service />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Rent />
                        </Grid>
                        <Grid item style={{ paddingTop: "25px" }}>
                          <Promotion />
                        </Grid>
                        {admin === false ? (
                          <Grid item style={{ paddingTop: "25px" }}>
                            <BottomHint />
                          </Grid>
                        ) : (
                          ""
                        )}
                        {admin === false ? (
                          <Grid
                            item
                            style={{
                              paddingTop: "25px",
                              paddingBottom: "15px",
                            }}
                          >
                            <Box
                              // component="span"
                              sx={{
                                p: 2,
                                border: "1px dashed lightGrey",
                                borderRadius: "5px",
                              }}
                            >
                              <DropPicture />
                            </Box>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item>
                          <ImageCard />
                        </Grid>
                        {url === "orm" ? (
                          <Grid item style={{ paddingTop: "25px" }}>
                            <NotRegisteringHint />
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid
                          item
                          container
                          style={{ paddingTop: "25px" }}
                          justifyContent="flex-start"
                        >
                          {edit === true ? (
                            <Button
                              variant="contained"
                              disableElevation
                              size="large"
                              style={{ color: "#ffffff" }}
                              onClick={clickable}
                            >
                              {url === "orm"
                                ? "SICHERN OHNE REGISTRIERUNG"
                                : "SICHERN"}
                            </Button>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={1} xl={1}>
                    {/* <Item>xs</Item> */}
                  </Grid>
                  <Grid item xs={1} xl={1}>
                    {/* <Item>xs</Item> */}
                  </Grid>
                  {url === "orm" ? (
                    <Grid item xs={10} xl={10}>
                      <Card>
                        <CardContent>
                          <Grid item>
                            <Register />
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : (
                    <Grid item xs={10} xl={10} />
                  )}
                  <Grid item xs={1} xl={1}>
                    {/* <Item>xs</Item> */}
                  </Grid>
                  <Grid item xs={1} xl={1} />
                  <Grid item xs={10} xl={10}>
                    {admin === true ? (
                      <React.Fragment>
                        <Grid container spacing={2}>
                          {fileDescription.map((file: any, v: number) => (
                            <Grid item xs={12} sm={12} md={6} xl={4} key={v}>
                              <Card sx={{ maxWidth: 700 }}>
                                <Grid container spacing={0}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    style={{ padding: "20px 10px 20px 15px" }}
                                  >
                                    <CardMedia
                                      // component={() => (
                                      //   <img
                                      //     alt="test"
                                      //     width="auto"
                                      //     // height="150"
                                      //     src={`${file.base}`}
                                      //   />
                                      // )}
                                      component="img"
                                      width="120"
                                      height="150"
                                      // component="img"
                                      src={file.base}
                                      // alt="green iguana"
                                      // style={{ borderRadius: "7px" }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={6}>
                                    <CardContent>
                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          style={{ paddingBottom: "5px" }}
                                        >
                                          <TextField
                                            multiline
                                            placeholder="Bildtitel"
                                            maxRows={4}
                                            minRows={4}
                                            fullWidth={true}
                                            size="small"
                                            value={file.description}
                                            // onChange={(e) => handleChangeState(e, file.name)}
                                          />
                                        </Grid>
                                        <Grid item xs={10} md={10}>
                                          <Typography
                                            style={{
                                              paddingTop: "8px",
                                              paddingRight: "8px",
                                            }}
                                            gutterBottom
                                            variant="subtitle1"
                                            component="div"
                                            color="secondary"
                                            noWrap={true}
                                          >
                                            Reihenfolge: {file.order}
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={2} md={2}></Grid>
                                      </Grid>
                                    </CardContent>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <Grid item xs={10} xl={10} />
                    )}
                  </Grid>
                  <Grid item xs={1} xl={1} />
                </Grid>
              </Grid>
            )}
          </Box>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}
